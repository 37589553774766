<template>
  <b-modal
    visible
    scrollable
    centered
    size="lg"
    :title="'Создание нового отделения'"
    :no-close-on-backdrop="noCloseOnBackdrop"
    @hidden="onClose"
    @ok="onClose"
  >
    <loading v-if="isLoading">
      Загрузка данных
    </loading>
    <div>
      <b-row>
        <b-col>
          <b-form-group
            label="Код отделения"
          >
            <b-form-input
              v-model="$v.code.$model"
              class="w-25"
              type="text"
              :state="validateState('code')"
              :placeholder="'Введите код'"
              autocomplete="new-branch"
              trim
            />
            <b-form-invalid-feedback
              id="input-1-live-feedback"
            >
              Данное поле обязательно к заполнению
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            class="mb-0"
            label="Режим работы"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col class="mb-3">
          <b-form-input
            v-model="branchOperatingHoursFrom"
            v-maska="'##:##'"
            type="text"
            :placeholder="'Начало работы'"
            autocomplete="new-branch"
            trim
          />
        </b-col>

        <b-col>
          <b-form-input
            v-model="branchOperatingHoursTo"
            v-maska="'##:##'"
            type="text"
            :placeholder="'Окончание работы'"
            autocomplete="new-branch"
            trim
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Город">
            <v-select
              v-model="$v.branchCityId.$model"
              :reduce="city => city.id"
              :options="cities"
              placeholder="Выберите город"
              label="name"
              :clearable="true"
              autocomplete="new-city"
              class="legalPartnerSelect  mr-2"
              @input="fetchMetroData"
            />
            <span
              v-if="$v.branchCityId.$error"
              id="input-1-live-feedback"
              class="error-text"
            >
              Данное поле обязательно*
            </span>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Адрес"
          >
            <b-form-input
              v-model="$v.address.$model"
              type="text"
              :state="validateState('address')"
              :placeholder="'Введите адрес'"
              autocomplete="new-address"
              trim
            />
            <b-form-invalid-feedback
              id="input-1-live-feedback"
            >
              Данное поле обязательно и должно быть не менее 5 символов*
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="isMetro">
        <b-col>
          <b-form-group
            label="Метро"
          >
            <multiselect
              v-model="subway"
              :multiple="true"
              placeholder="Выберите метро"
              :close-on-select="false"
              label="name"
              track-by="id"
              :options="mainMetroData"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Отделение на карте">
            <yandex-map
              :coords="coords"
              :zoom="10"
              :scroll-zoom="false"
              @click="onClick"
            >
              <ymap-marker
                :coords="coords"
                marker-id="123"
                :hint-content="`Отделение`"
              />
            </yandex-map>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Юрлицо">
            <b-form-select
              v-model="$v.legalPartnerId.$model"
              value-field="id"
              text-field="legalName"
              label="legalName"
              trim
              :options="optionsLegalPartner"
            />
            <div
              v-if="!legalPartnerId"
              class="mt-1 text-danger small"
            >
              {{ errorsValidation.legalPartnerId[0] }}
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Активирован">
            <b-form-select
              v-model="isActive"
              :options="optionsActivate"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <template #modal-footer>
      <div>
        <b-button
          variant="primary"
          :type="$const.PRIMARY_BUTTON"
          @click="onClickCreateLegalPartner"
        >
          Создать
        </b-button>
        <b-button
          variant="danger"
          :type="$const.PRIMARY_BUTTON"
          class="ml-2"
          @click="onClose"
        >
          Отмена
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { minLength, required } from 'vuelidate/lib/validators';
import Loading from '@/components/Loading';
import { yandexMap, ymapMarker } from 'vue-yandex-maps';

export default {
  name: 'AddBranchModal',
  components: {
    Loading,
    yandexMap,
    ymapMarker,
  },
  mixins: [validationMixin],
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    params: {
      type: Object,
      default: null,
    },
    cities: {
      type: Array,
      default: null,
    },
    noCloseOnBackdrop: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      rows: [],
      branchName: '',
      branchOperatingHoursFrom: '',
      branchOperatingHoursTo: '',
      branchCityId: null,
      code: '',
      address: '',
      isActive: null,
      subway: [],
      subwayColor: '',
      legalPartnerId: '',
      optionsLegalPartner: [],
      optionsActivate: [
        {
          value: null,
          text: 'Выберите состояние',
        },
        {
          value: true,
          text: 'Активирован',
        },
        {
          value: false,
          text: 'Деактивирован',
        },
      ],
      mainMetroData: [],
      hhCityId: null,
      metroCities: [
        {
          name: 'Москва',
          id: 1,
        },
        {
          name: 'Санкт-Петербург',
          id: 2,
        },
        {
          name: 'Екатеринбург',
          id: 3,
        },
        {
          name: 'Новосибирск',
          id: 4,
        },
        {
          name: 'Cамара',
          id: 78,
        },
        {
          name: 'Казань',
          id: 88,
        },
        {
          name: 'Нижний Новгород',
          id: 66,
        },
      ],
      metroData: [],
      isMetro: false,
      coords: [
        55.7558,
        37.6173,
      ],
    };
  },
  validations: {
    legalPartnerId: {
      required,
    },
    code: {
      required,
      minLength: minLength(1),
    },
    branchCityId: { required },
    address: {
      required,
      minLength: minLength(5),
    },
  },
  computed: {
    legalPartnerListStore() {
      return this.$store.state.Laboratories.legalPartnerList;
    },
    branchLatitude() {
      return this.coords[0];
    },
    branchLongitude() {
      return this.coords[1];
    },
    errorsValidation() {
      const errors = {};

      errors.legalPartnerId = [];
      if (!this.$v.legalPartnerId.required) {
        errors.legalPartnerId.push('Данное поле обязательно*');
      }
      return errors;
    },
  },
  async created() {
    await this.$store.dispatch(this.$types.GET_LABORATORY_LEGAL_PARTNERS, {});
    this.optionsLegalPartner = this.legalPartnerListStore.data;
  },
  methods: {
    async onClose() {
      this.$emit('input', false);
    },
    onClick(e) {
      this.coords = e.get('coords');
    },
    setMapPoint() {
      const currentCity = this.cities.filter((item) => item.id === this.branchCityId)[0];

      this.coords = [
        currentCity.latitude,
        currentCity.longitude,
      ];
    },
    async fetchMetroData() {
      this.setMapPoint();

      await this.checkMetroFromCity();

      if (this.isMetro) {
        this.metroData = await this.$store.dispatch(this.$types.GET_METRO_FOR_CITY, this.hhCityId);
        this.mainMetroData = [];

        for (let i = 0; i < this.metroData.data.lines.length; i += 1) {
          for (let y = 0; y < this.metroData.data.lines[i].stations.length; y += 1) {
            this.metroData.data.lines[i].stations[y].hexColor = this.metroData.data.lines[i].hex_color;
            this.mainMetroData.push(this.metroData.data.lines[i].stations[y]);
          }
        }
      }
    },
    checkMetroFromCity() {
      const currCity = this.cities.filter((item) => item.id === this.branchCityId)[0];

      for (let i = 0; i < this.metroCities.length; i += 1) {
        if (currCity.name === this.metroCities[i].name) {
          this.hhCityId = this.metroCities[i].id;
          this.isMetro = true;

          break;
        }

        if (currCity.name !== this.metroCities[i].name) {
          this.hhCityId = null;

          this.isMetro = false;
        }
      }

      return currCity;
    },
    async onClickCreateLegalPartner() {
      if (!this.onCheckValidation()) return;

      const currMetro = this.mainMetroData.filter((item) => this.subway === item.name);
      if (currMetro[0]) this.subwayColor = currMetro[0].hexColor;

      const params = {
        code: this.code,
        name: this.branchName,
        cityId: this.branchCityId,
        legalPartnerId: this.legalPartnerId,
        address: this.address,
        longitude: this.branchLongitude,
        latitude: this.branchLatitude,
        isActive: this.isActive ?? false,
        operatingHoursTo: this.branchOperatingHoursTo,
        operatingHoursFrom: this.branchOperatingHoursFrom,
        subwayStations: this.subway?.map((item) => item.name) || [],
        subwayColor: this.subwayColor,
      };
      console.log(params);
      await this.$store.dispatch(this.$types.LABORATORY_BRANCH_CREATE, params);
      await this.$store.dispatch(this.$types.GET_LABORATORY_BRANCHES, this.params);

      this.onClose();
    },
    validateState(name) {
      const {
        $dirty,
        $error,
      } = this.$v[name];
      return $dirty ? !$error : null;
    },
    onCheckValidation() {
      this.$v.legalPartnerId.$touch();
      this.$v.branchCityId.$touch();
      this.$v.address.$touch();
      this.$v.code.$touch();
      if (this.$v.legalPartnerId.$anyError
        || this.$v.branchCityId.$anyError
        || this.$v.address.$anyError
        || this.$v.code.$anyError
      ) {
        return false;
      }
      return true;
    },
    formatInn(e) {
      return String(e)
        .substring(0, 12);
    },
    formatKpp(e) {
      return String(e)
        .substring(0, 9);
    },
  },
};
</script>

<style lang="scss" scoped>

.crm-table {
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  &-row {
    display: flex;

    &--header {
      background: #F3F3F3;
      color: #6E88F3;
    }

    & + & {
      border-top: 1px solid #E0E0E0;
    }
  }

  &-cell {
    padding: 10px;
    width: 100%;

    &--date {
      flex-shrink: 0;
      width: 150px;
    }

    & + & {
      border-left: 1px solid #E0E0E0;
    }
  }
}

.error-text {
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.ymap-container {
  height: 350px;
}
</style>
